import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  crmMemberprofileData: {
    firstName: "",
    middleName: "",
    lastName: "",
    salutation: ""
  },
  isLoading: false,
  error: "",
  hasData: false,
  crmCountryCodeData: [],
  crmnewsletterPreferenceData: [],
  crmresidenceData: []
};
const fetchCrmProfileSlice = createSlice({
  name: "CrmProfileDeails",
  initialState,
  reducers: {
    fetchCrmMemberProfileRequest: state => {
      state.isLoading = true;
    },
    getCrmProfileSuccess: (state, action) => {
      var _action$payload, _action$payload2, _action$payload3, _action$payload4, _action$payload5, _action$payload6, _action$payload7, _action$payload8;
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.crmMemberprofileData = action.payload;
      console.log("crmstate", action.payload);
      const fullName = ((_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.salutation) + " " + ((_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.firstName) + " " + ((_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.lastName);
      sessionStorage.setItem("FullName", fullName);
      sessionStorage.setItem("FirstName", action === null || action === void 0 || (_action$payload4 = action.payload) === null || _action$payload4 === void 0 ? void 0 : _action$payload4.firstName);
      sessionStorage.setItem("LastName", action === null || action === void 0 || (_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.lastName);
      sessionStorage.setItem("Salutation", action === null || action === void 0 || (_action$payload6 = action.payload) === null || _action$payload6 === void 0 ? void 0 : _action$payload6.salutation);
      sessionStorage.setItem("profileName", action === null || action === void 0 || (_action$payload7 = action.payload) === null || _action$payload7 === void 0 ? void 0 : _action$payload7.firstName);
      sessionStorage.setItem("customerID", action === null || action === void 0 || (_action$payload8 = action.payload) === null || _action$payload8 === void 0 ? void 0 : _action$payload8.customerId);
    },
    getCrmProfileFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.crmMemberprofileData = {};
    },
    fetchCRMCountryCodeRequest: state => {
      state.isLoading = true;
    },
    getCRMCountryCodeSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.crmCountryCodeData = action.payload;
    },
    getCRMCountryCodeFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.crmCountryCodeData = [];
    },
    fetchCRMnewsletterPreferenceRequest: state => {
      state.isLoading = true;
    },
    getCRMnewsletterPreferenceSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      let preference = [];
      preference = action.payload.map(item => ({
        city: item.city,
        country: item.city + " " + "-" + " " + item.country,
        code: item.code,
        airportCityCode: item.airportCityCode
      }));
      state.crmnewsletterPreferenceData = preference;
    },
    getCRMnewsletterPreferenceFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.crmnewsletterPreferenceData = [];
    },
    fetchCRMresidenceRequest: state => {
      state.isLoading = true;
    },
    getCRMresidenceSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.crmresidenceData = action.payload;
    },
    getCRMresidenceFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.hasData = false;
      state.crmresidenceData = [];
    }
  }
});
export const {
  fetchCrmMemberProfileRequest,
  getCrmProfileSuccess,
  getCrmProfileFailure,
  fetchCRMCountryCodeRequest,
  getCRMCountryCodeSuccess,
  getCRMCountryCodeFailure,
  fetchCRMnewsletterPreferenceRequest,
  getCRMnewsletterPreferenceSuccess,
  getCRMnewsletterPreferenceFailure,
  fetchCRMresidenceRequest,
  getCRMresidenceSuccess,
  getCRMresidenceFailure
} = fetchCrmProfileSlice.actions;
export default fetchCrmProfileSlice.reducer;